/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { isAndroid } from 'react-device-detect';
import Img from 'react-image';
import TagManager from 'react-gtm-module';
import ReactPlayer from 'react-player';
import dynamic from 'next/dynamic';
import NextErrorComponent from 'next/error';
import axios from 'axios';
import NProgress from 'nprogress';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { find } from 'lodash';
import { useTranslation as i18nUseTranslation } from 'react-i18next';
// import liff from '@line/liff';

import * as selectors from '@src/store/selectors';
import * as actions from '@src/store/actions';
import reduxWrapper from '@src/store';
import { reactLocalStorage } from '@src/utils/localStorage';
import { checkIsServer, getApiData } from '@src/utils/deprecatedCommon';
import * as Sentry from '@sentry/node';
import { Integrations } from '@sentry/tracing';
import { colorThemes } from '@src/const/theme';
import config from '@src/config';
import { defaultActionCalls } from '@src/router/ssr';
import { LOCALE } from '@src/const/common';
import App from '@src/App';
import BasicSeo from '@src/components/seo/basicSeo';
import FBMessenger from '@src/components/seo/basicSeo/fbMessenger';
import NoIndex from '@src/components/seo/basicSeo/noIndex';
import PreviewCover from '@src/components/common/previewCover';
import device from '@src/styles/breakpoint';
import FBPixelCode from '@src/components/seo/basicSeo/fbPixelCode';
import '@src/styles/styles.scss';

const GlobalStyle = createGlobalStyle`
	.ant-notification {
		width: 100%;
	}
	.pwa-toast {
		box-shadow: unset !important;
		padding: unset !important;
		background: rgba(0, 0, 0, 0) !important;
		width: unset !important;
		display: flex;
		justify-content: center;
		& .ant-notification-notice-content {
			padding: 12px 20px;
			background: #222;
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		& .ant-notification-notice-message {
			width: fit-content;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			color: #FFFFFF;
			padding: unset;
			margin: unset;
			padding-right: 0px !important;
		}
		& .ant-notification-notice-close {
			display: none;
		}
	}
`;

const saveAffiliateParams = ({ key, value }) => {
	if (value) {
		reactLocalStorage.set(key, value);
	}
};

// 關閉特定警告，避免拖慢速度
const closeConsoleWarn = () => {
	const IGNORED_WARNINGS = [
		'Warning: componentWillReceiveProps has been renamed',
		'Warning: componentWillUpdate has been renamed',
		'Warning: componentWillMount',
		'ResizeObserver loop limit exceeded',
	];
	const oldConsoleWarn = console.warn;

	console.warn = (...args) => {
		if (
			typeof args[0] === 'string' &&
      IGNORED_WARNINGS.some(ignored => args[0].startsWith(ignored))
		) {
			return;
		}

		return oldConsoleWarn.apply(console, args);
	};

	// error

	const IGNORED_ERRORS = ['Warning: Text content did not match. Server'];

	const oldConsoleError = console.error;

	console.error = (...args) => {
		if (
			typeof args[0] === 'string' &&
      IGNORED_ERRORS.some(ignored => args[0].startsWith(ignored))
		) {
			return;
		}

		return oldConsoleError.apply(console, args);
	};
};

closeConsoleWarn();

const { isTest, useSentry } = config;

if (useSentry) {
	Sentry.init({
		dsn:
      'https://b41ef2adfc9440d8a18db17d9556558e@o455515.ingest.sentry.io/5525113',
		release: process.env.SENTRY_RELEASE,
		tracesSampleRate: 1.0,
		integrations: [new Integrations.BrowserTracing()],
	});

	const token = reactLocalStorage.get('token');

	Sentry.setContext('header', {
		token: `${token}`,
	});
}

const NotMatchPage = dynamic(() => import('@src/pages/NotMatchPage'), {
	ssr: false,
});

if (!checkIsServer()) {
	// Setup API Domain in Client Side
	const apiDomain = isTest ?
		process.env.NEXT_PUBLIC_HOSTNAME :
		window.location.host;

	axios.defaults.baseURL = `https://${apiDomain}/api`;

	// 關閉IMG預設解碼svg在ios上無法顯示的錯誤
	Img.defaultProps = {
		...Img.defaultProps,
		decode: false,
	};

	// android開啟playsinline，避免android在line中無法播放的問題
	if (isAndroid) {
		ReactPlayer.defaultProps = {
			...ReactPlayer.defaultProps,
			playsinline: true,
		};
	}
}

const isPublicPage = path => path.indexOf('/business') === -1 &&
		path.indexOf('/action') === -1 &&
		path.indexOf('/preview') === -1 &&
		path.indexOf('/p/') === -1 &&
		path.indexOf('/category/') === -1 &&
		path.indexOf('/event/[eventShortLink]/[id]') === -1 &&
		path.indexOf('/jobs/[id]') === -1;

function MyApp(props) {
	const { Component, pageProps, domain, ssrHeaders, statusCode } = props;

	const { i18n } = i18nUseTranslation();
	const router = useRouter();
	const dispatch = useDispatch();
	const { themes } = useSelector(selectors.getThemeSelector);
	const {
		gtm_id, closed_web, language_preference, multi_language_enabled,
		fb_page_id, fb_chat_plugin_enabled, noindex, fb_pixel_id,
	} = themes;
	const isSignedIn = reactLocalStorage.get('isSignedIn');
	const { previewToken, previewCallback } = router.query;

	const isPreviewPage = () => !!previewToken;

	useEffect(() => {
		// 前台暫時關閉 Liff
		// liff.init({
		// 	liffId: process.env.NEXT_PUBLIC_LIFF_ID,
		// });
	}, []);


	useEffect(() => {
		const { query } = router;
		const { action, fromCompanyVat } = query || {};
		if (action === 'handle_conflict_email') {
			dispatch(actions.setModalType({ type: 'conflictEmail' }));
		}

		if (fromCompanyVat && !isSignedIn && !closed_web) {
			dispatch(actions.setModalType({ type: 'login' }));
		}

		if (
			language_preference &&
			i18n.language.toLocaleLowerCase() !== LOCALE[language_preference].toLocaleLowerCase()
		) {
			i18n.changeLanguage(LOCALE[language_preference]);
		}
		if (!multi_language_enabled) {
			i18n.changeLanguage('zh-tw');
		}
	}, [router]);

	useEffect(() => {
		const { query: {
			track_id,
			utm_campaign,
			utm_medium,
			utm_source,
			valid_until,
		} } = router;
		saveAffiliateParams({ key: 'track_id', value: track_id });
		saveAffiliateParams({ key: 'utm_campaign', value: utm_campaign });
		saveAffiliateParams({ key: 'utm_medium', value: utm_medium });
		saveAffiliateParams({ key: 'utm_source', value: utm_source });
		saveAffiliateParams({ key: 'valid_until', value: valid_until });
	}, []);

	useEffect(() => {
		if (!isTest && gtm_id) { // PS. gtm id setting is on the dashboard.
			const tagManagerArgs = {
				gtmId: gtm_id,
			};
			TagManager.initialize(tagManagerArgs);
		}
		if (isPreviewPage()) {
			dispatch(actions.setPreviewToken({
				token: previewToken,
			}));
		} else if (isSignedIn && isSignedIn !== 'false') {
			dispatch(actions.checkIsSignedIn());
			dispatch(actions.checkLoginCall({ fromLogin: false, ssrHeaders }));
		} else {
			// 未登入狀態
			if (!isPreviewPage()) reactLocalStorage.remove('previewToken');
			if (closed_web && isPublicPage(router.pathname)) {
				router.push('/business-login');
			}
		}
  }, []); // eslint-disable-line

	// Animation for changing page url
	useEffect(() => {
		const handleRouteChangeStart = url => {
			NProgress.start();
		};
		router.events.on('routeChangeStart', handleRouteChangeStart);

		const handleRouteChangeComplete = url => {
			NProgress.done();
		};
		router.events.on('routeChangeComplete', handleRouteChangeComplete);

		return () => {
			router.events.off('routeChangeStart', handleRouteChangeStart);
			router.events.off('routeChangeComplete', handleRouteChangeComplete);
		};
  }, []); // eslint-disable-line

	if (statusCode !== 200) {
		if (statusCode === 404) {
			return <NotMatchPage />;
		} else {
			return <NextErrorComponent statusCode={statusCode} />;
		}
	}

	const themeName = themes.color_theme;
	const selectedTheme = find(colorThemes, theme => theme.id === themeName) || colorThemes[0];
	const themeObj = {
		device,
		colors: selectedTheme?.colors,
	};

	const isNotLoginCloseWeb = closed_web &&
		(isSignedIn === 'false' || isSignedIn === null) &&
		isPublicPage(router.pathname) && !isPreviewPage();
	if (isNotLoginCloseWeb) {
		console.log('企業封閉頁，需要登入才能檢視'); // 留下 log 方便未來如果出問題時追蹤
	}

	return (
		<ThemeProvider theme={themeObj}>
			<GlobalStyle />
			<FBMessenger id={fb_page_id} enable={fb_chat_plugin_enabled} />
			<NoIndex enable={noindex} />
			{fb_pixel_id && <FBPixelCode pixelId={fb_pixel_id} />}
			{
				!isNotLoginCloseWeb && (
					<App className="app-container">
						<BasicSeo domain={domain} />
						{previewToken && <PreviewCover callbackUrl={previewCallback} />}
						<Component themeName={themeName} {...pageProps} />
					</App>
				)
			}
		</ThemeProvider>
	);
}

// Hardcode for 301 redirect.
const redirectConfigs = {
	// source: target
	'https://abc.staging.teaches.cc': 'https://yaoyan.staging.teaches.cc',
	'https://brightsidetw.teaches.cc': 'https://ucasalon-learningcenter.teaches.cc',
};

MyApp.getInitialProps = async props => {
	const { Component, ctx } = props;
	const { store, req, res = {} } = ctx;
	const statusCode = res?.statusCode;
	const { apiDomain, ssrHeaders, domain, ssrIsMobile } = getApiData(req);

	axios.defaults.baseURL = apiDomain;

	const success = await new Promise(resolve => {
		const unsubscribe = store.subscribe(() => {
			const state = store.getState();
			const defaultActionDone = defaultActionCalls.actionDone(ctx);
			const themeState = selectors.getThemeState(state);
			if (defaultActionDone) {
				if (themeState === 'NOT_FOUND') {
					res.writeHead(301, { Location: redirectConfigs[domain] || 'https://teaches.cc' });
					res.end();
				} else {
					resolve(unsubscribe);
				}
			}
		});

		defaultActionCalls.actionCall(ctx);
	});
	success();

	return {
		pageProps: {
			// Call page-level getInitialProps
			...(Component.getInitialProps ?
				await Component.getInitialProps(ctx) :
				{}),
			ssrIsMobile,
		},
		domain,
		ssrHeaders,
		statusCode,
		ssrIsMobile,
	};
};

export default reduxWrapper.withRedux(MyApp);
